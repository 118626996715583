<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      {{$t('copyright_footer')}}


<!--      © {{ new Date().getFullYear() }}-->

<!--      <b-link-->
<!--        class="ml-25"-->
<!--        :href="url"-->
<!--        target="_blank"-->
<!--      >  {{ $t('reinvest')}} </b-link>-->
<!--      <span class="d-none d-sm-inline-block">&nbsp;,&nbsp; {{$t('all_rights_Reserved')}}</span>-->
    </span>

   </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import { mainURL } from '@/services/url.service'

export default {
  data() {
    return {
      url:null
    }
  },
  components: {
    BLink,
  },

  created() {
     this.url=mainURL()
  }
}
</script>
